.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}


.testimonial {
  height: 25rem;
  /* width: 100vw; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, white, white, hsl(39, 77%, 99%));
}
.testimonial-container {
  width: 50vw;
  text-align: center;
}
.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}

@media screen and (max-width: 767px) {
  .testimonial h1 {
    font-size: 1.7rem;
  }
  .testimonial-container {
    width: 80vw;
  }
  .testimonial .slick-arrow.slick-prev,
  .testimonial .slick-arrow.slick-next {
    display: none;
  }
  .cta-mobile {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}

