.aboutHome {
  height: auto;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
}
.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
}
.aboutHome .right {
  padding: 80px 50px;
  margin-top: 5rem;
}
.aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
  z-index: 3;
}
.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #999999;
}
.aboutHome .item:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}
/*----------awrapper------------*/
.awrapper {
  background-image: url("../../../public/images/awrapper.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 40vh;
  color: #fff;
  position: relative;
}
.awrapper .box {
  padding: 70px 0;
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 50px;
}
.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
}
.shade {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.075);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 70vh;
    width: 100vw;
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
  }
  .aboutHome .right {
    padding: 90px 10px;
    margin-top: -3rem;
  }
  .awrapper .box {
    padding: 20px 0;
    flex-direction: column;
    margin-left: 1.7rem;
  }
}
.text {
  z-index: 3;
}
